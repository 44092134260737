<template>
  <div class="row">
    <div class="col-12">
      <div v-for="pickupday in pickupDays" :key="pickupday.id">
        <div class="card mt-4">
          <div class="card-body">
            <div class="table-orders">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span class="text-muted font-size-14">
                    {{ createDate(pickupday.date) }}
                  </span>
                  <h2>
                    {{ createDay(pickupday.date) }}
                  </h2>
                </div>
                <div class="col-12 col-sm-8 mt-n4 mt-sm-0 pt-2">
                  <div class="row justify-content-end">
                    <div class="col-12 col-md-auto pt-2 text-right">
                      <div class="badge-group">
                        <span class="badge badge-secondary text-green font-size-18" style="height: 30px; line-height: 2rem">
                          {{ pickupday.completed_trucks }}<i class="icon-truck" style="margin-top: 2px"></i>
                        </span>
                        <span class="badge badge-secondary text-orange font-size-18" style="height: 30px; line-height: 2rem">
                          {{ pickupday.arrived_trucks }}<i class="icon-truck" style="margin-top: 2px"></i>
                        </span>
                        <span class="badge badge-secondary font-size-18" style="height: 30px; line-height: 2rem">
                          {{ pickupday.planned_trucks }}<i class="icon-truck" style="margin-top: 2px"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-compact table-striped table-responsive-lg">
                <thead>
                <tr>
                  <th class="th"></th>
                  <th class="th">#</th>
                  <th class="th">{{ $t('time-label') }}</th>
                  <th class="th">{{ $t('area-label') }}</th>
                  <th class="th">{{ $t('pipes-label') }}</th>
                  <th class="th" v-if="coolerTruck">{{ $t('pallets-label') }}</th>
                  <th class="th">{{ $t('license-plate-label') }}</th>
                  <th class="th">{{ $t('comments-label') }}</th>
                  <th class="th">{{ $t('status-label') }}</th>
                  <th class="th"></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="truck in pickupday.trucks" :key="truck.id">
                    <td></td>
                    <td>{{ truck.sort }}</td>
                    <td>
                      <span v-if="truck.status === 'planned-label'" class="text-grey">{{ truck.planned_arrival ? createTime(truck.planned_arrival) : '-:-' }}</span>
                      <span v-if="truck.status === 'arrived-label'" class="text-orange">{{ truck.arrival_time ? createTime(truck.arrival_time) : '-:-' }}</span>
                      <span v-if="truck.status === 'completed-left-label'">{{ truck.leaving_time ? createTime(truck.leaving_time) : '-:-' }}</span>
                    </td>
                    <td>
                      {{ truck.area }} m2
                    </td>
                    <td>
                      {{ truck.number_of_rolls}} {{ $t('pcs-label') }}
                    </td>
                    <td v-if="coolerTruck">
                      {{ truck.pallets }} {{ $t('pcs-label') }}
                    </td>
                    <td>
                      {{ truck.license_plate_1 }}
                      {{ truck.license_plate_2 ? '/' + truck.license_plate_2 : '' }}
                    </td>
                    <td>
                      <div v-if="truck.comment" class="truck-tooltip">
                        <i class="icon-comment"></i>
                        <span class="tooltiptext">{{truck.comment}}</span>
                      </div>
                      <div v-if="truck.arrival_phone" class="truck-tooltip" style="padding-left: 5px">
                        <i class="icon-phone"></i>
                        <span class="tooltiptext">{{truck.arrival_phone}}</span>
                      </div>
                    </td>
                    <td>
                      <i v-if="truck.status === 'planned-label'" class="icon-truck text-grey"></i>
                      <i v-if="truck.status === 'arrived-label'" class="icon-truck text-orange"></i>
                      <i v-if="truck.status === 'completed-left-label'" class="icon-truck text-green"></i>
                    </td>
                    <td>
                      <a v-if="truck.delivery_note" v-on:click="downloadDeliveryNote(truck.id)" class="btn btn-sm btn-success">
                        <i class="icon-download"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";
import {default as axios} from "axios";
import {logout} from "@/main";

export default {
  name: 'pickup-days',
  props: ['pickupDays', 'coolerTruck'],
  methods: {
    createDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    createDay(date) {
      return moment(date).format('dddd')
    },

    createTime(date) {
      moment.locale('hu')
      return moment(date).format('LT')
    },

    downloadDeliveryNote(id){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'trucks/'+id+'/download-delivery-note', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        },
        responseType: 'blob'
      }).then(function (response) {
        window.open(URL.createObjectURL(response.data))
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    }
  }
}
</script>