import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=6aa64c3d&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Dashboard.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Funpkg.com%2Fselect2%404.0.3%2Fdist%2Fcss%2Fselect2.min.css"
if (typeof block0 === 'function') block0(component)

export default component.exports