<template>
  <div class="app orders py-5">
    <div class="row mb-4" v-if="this.season_closed === false">
      <div class="col-6 pr-1 pr-md-3 col-md-auto mb-4 mb-md-0 ordering-container pr-sm-1" v-show="this.garden_stock === true">
        <router-link to="/orders/create/garden">
          <div class="card">
            <div class="card-body">
              <div class="icon-container">
                <img src="../assets/icon-pannon.svg">
              </div>
              <i>pannon</i>
              <h4>GARDEN</h4>
              <div class="btn btn-link">{{ $t('order-now-label') }} <i class="icon-arrow text-green icon-right"></i></div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-6 pl-1 pl-md-3 col-md-auto ordering-container" v-show="this.med_stock === true">
        <router-link to="/orders/create/mediterranean">
          <div class="card">
            <div class="card-body">
              <div class="icon-container">
                <img src="../assets/icon-pannon.svg">
              </div>
              <i>pannon</i>
              <h4>Mediterran</h4>
              <div class="btn btn-link">{{ $t('order-now-label') }} <i class="icon-arrow text-green icon-right"></i></div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col">

        <div v-if="!this.ordersLoaded">
          <Loader/>
        </div>

        <div class="tab-content" v-if="this.ordersLoaded">
          <div class="tab-content-inner  py-4 px-2 px-lg-4 py-lg-4">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                <h3>{{ $t('open-orders-label') }}</h3>
              </div>
              <div class="col-auto">
                <div class="summary">
                  <div class="row">
                    <div class="col-auto d-none">
                      <div class="status status-blue"></div>{{orders.total && orders.total.active.big}} m<sup>2</sup>
                    </div>
                    <div class="col-auto">
                      <div class="status status-success"></div>{{orders.total && orders.total.active.small}} m<sup>2</sup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-content text-center mt-4 pb-5" v-if="orders.active && Object.keys(orders.active).length === 0">
              <h2>{{ $t('no-orders-label') }}</h2>
            </div>
            <div class="panel-content pb-2" v-if="this.orders.active">
              <div v-for="(order, index) in orders.active" v-bind:key="order.id">
                <h6 class="mt-4">{{index}}</h6>
                <OrdersTable :orders="order"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrdersTable from '../components/Modules/OrdersTable'
import Loader from '../components/Modules/Loader'
import {logout} from "@/main";
const axios = require('axios').default;

export default {
  name: 'Orders',
  data(){
    return {
      orders: {},
      ordersLoaded: false,
      garden_stock: false,
      season_closed: false,
      med_stock: false
    }
  },
  components: {
    OrdersTable,
    Loader
  },
  methods:{
    getLabels(){
      const self = this
      axios.post(process.env.VUE_APP_API_URL+'labels/?locale=en', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function () {
        //console.log(response)
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    getOrders(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'orders', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.orders = response.data.data
        self.ordersLoaded = true
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    getStockInfo(){
      const self = this;
      axios.get(process.env.VUE_APP_API_URL + 'season-last-login', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("info",JSON.stringify(response.data));

          self.garden_stock = response.data.garden_stock
          self.med_stock = response.data.med_stock
          self.season_closed = response.data.season_closed
        }
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        console.log(error)
      });
    }
  },
  beforeMount() {
    this.getStockInfo();
    this.getOrders();
    //this.getLabels();
  }
}
</script>
