<template>
  <div class="tab-content">
    <div class="tab-content-inner tab-content-inner-first p-4">


      <div class="row mb-4 justify-content-between">
        <div class="col-10 col-md-auto">
          <h3>{{ $t('shipping-address-label') }}</h3>
        </div>
        <div class="col-2 col-md-auto">
          <svg v-tooltip="$t('address-component-tooltip-label')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="24" height="24"
               viewBox="0 0 24 24"
               style=" fill:#000000;"> <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>
        </div>
      </div>

      <div class="row" v-show="addresses[0]">
        <div class="col-12">
          <h6>{{$t('saved-addresses-label')}}</h6>
        </div>
      </div>

      <div class="row" v-show="addresses[0]">
        <div class="col-lg-6 col-sm-12">
          <div class="form-group">
            <div class="select-box compact">
              <label for="select-address">{{$t('select-address-label')}}</label>
              <select class="select" name="select-address" id="select-address" v-model='address.address_id' v-select='address.id' v-on:change="changeAddress()">
                <option value="">{{$t('please-select-label')}}</option>
                <optgroup :label="$t('saved-addresses-label')">
                  <option :selected="item.id === address.address_id" :value="item.id" v-for="item in savedAddresses" :key="item.id"> {{ item.shipping_zip }} {{ item.shipping_city }} {{ item.shipping_street }}</option>
                </optgroup>
                <optgroup label="">
                  <option :selected="item.id === address.address_id" :value="item.id" v-for="item in addresses" :key="item.id"> {{ item.shipping_zip }} {{ item.shipping_city }} {{ item.shipping_street }}</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr v-show="addresses[0]">
      <div class="row pt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg">
                  <div class="form-group" :class="{ 'form-group-error': $v.address.shipping_country.$error }">
                    <div class="select-box compact">
                      <label>{{$t('country-label')}} <span>*</span></label>
                      <Select2 id="shipping_country" v-model="address.shipping_country" :options="countries" />
                    </div>
                    <div class="error">{{ $t('please-select-label') }}</div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12">
                  <div class="form-group" :class="{ 'form-group-error': $v.address.shipping_zip.$error }">
                    <div class="input-box">
                      <label>{{$t('post-code-label')}} <span>*</span></label>
                      <div class="input-group">
                        <input type="text" class="form-control" autocomplete="off" v-model="address.shipping_zip" v-on:keyup="getZipCity('zip')">
                      </div>
                      <div class="autocomplete" v-show="cities">
                        <ul>
                          <li v-for="(city,zip) in cities" :key="zip" v-on:click="copyZipCity('zip',zip,city)">
                            {{zip+' '+city}}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="error">{{ $t('this-field-is-required-label') }}</div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12">
                  <div class="form-group" :class="{ 'form-group-error': $v.address.shipping_city.$error }">
                    <div class="input-box">
                      <label>{{$t('city-label')}} <span>*</span></label>
                      <input type="text" class="form-control" autocomplete="off" v-model="address.shipping_city" v-on:keyup="getZipCity('city')">

                      <div class="autocomplete" v-show="zips">
                        <ul>
                          <li v-for="(city,zip) in zips" :key="zip" v-on:click="copyZipCity('city', zip, city)">
                            {{city+' '+zip}}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="error">{{ $t('this-field-is-required-label') }}</div>
                  </div>
                </div>

                <div class="col-12 col-lg">
                  <div class="form-group" :class="{ 'form-group-error': $v.address.shipping_street.$error }">
                    <div class="input-box">
                      <label>{{$t('address-label')}} <span>*</span></label>
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="address.shipping_street">
                      </div>
                    </div>
                    <div class="error">{{ $t('this-field-is-required-label') }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="checkbox-container">
                      <input type="checkbox" style="display:none" id="future_order" name="future_order" class="inp-cbx" v-model="address.saveAddress" value="1">
                      <label class="cbx square" for="future_order">
                    <span>
                      <svg viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg>
                    </span>
                        <span>{{$t('would-you-like-to-save-label')}}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="tab-content-inner p-4 mt-4">
      <div class="row">
        <div class="col-12 pt-3">
          <div class="row mb-4 justify-content-between">
            <div class="col-10 col-md-auto">
              <h3>{{ $t('choose-car-label') }}</h3>
            </div>
            <div class="col-2 col-md-auto">
              <svg v-tooltip="$t('car-component-tooltip-label')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   width="24" height="24"
                   viewBox="0 0 24 24"
                   style=" fill:#000000;"> <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6 v-show="cars[0]">{{ $t('saved-cars-label') }}</h6>

              <div class="row" v-show="cars[0]">
                <div class="col-12 col-lg-6">
                  <div class="form-group mb-0">
                    <div class="select-box compact">
                      <label for="select-car">{{$t('your-car-label')}}</label>
                      <select class="select" name="select-car" id="select-car" v-model="vehicles.car_id" v-select='vehicles.id' v-on:change="changeCar()">
                        <option value="">{{$t('please-select-label')}}</option>
                        <option :selected="item.id === vehicles.car_id" :value="item.id" v-for="item in cars" :key="item.id"> {{ item.text }}</option>
                      </select>
                    </div>
                    <div class="error">{{ $t('please-select-label') }}</div>
                  </div>
                </div>
              </div>

              <hr v-show="cars[0]">

              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <div class="form-group">
                    <div class="input-box">
                      <label>{{$t('license-plate-label')}}</label>
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="vehicles.license_plate">
                      </div>
                    </div>
                    <div class="error">{{ $t('this-field-is-required-label') }}</div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12">
                  <div class="form-group">
                    <div class="input-box">
                      <label>{{$t('loading-capacity-label')}}</label>

                      <div class="input-group mb-2">
                        <input type="text" class="form-control" v-model="vehicles.loading_capacity">
                        <div class="input-group-append">
                          <div class="input-group-text">kg</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {required} from "vuelidate/lib/validators";
import $ from 'jquery'
import 'select2'
const axios = require('axios').default;
import i18n from '../../i18n'

export default {
  name: 'Address',
  components: {
  },
  data(){
    return {
      address:{
        address_id: this.$parent.order.address_id,
        shipping_country: this.$parent.order.address.shipping_country ? this.$parent.order.address.shipping_country : 'hungary-label',
        shipping_city: this.$parent.order.address.shipping_city,
        shipping_street: this.$parent.order.address.shipping_street,
        shipping_zip: this.$parent.order.address.shipping_zip,
        saveAddress: false,
        saveCar: false,
        id: null
      },
      selectedCar: null,
      savedAddresses: [],
      allAddresses: [],
      addresses: [],
      countries: [],
      cars: [],
      tempCars: [],
      savedCars: [],
      zips:null,
      cities:null,
      vehicles:{
        car_id: null,
        license_plate: null,
        loading_capacity: null
      },
      editableCar: {
        id: null,
        license_plate: null,
        loading_capacity: null
      }
    }
  },
  validations: {
    address:{
      shipping_country: {
        required
      },
      shipping_city: {
        required
      },
      shipping_zip: {
        required
      },
      shipping_street: {
        required
      }
    }
  },
  methods:{
    getCars(){
      const self = this;

        axios.get(process.env.VUE_APP_API_URL+'cars', {
          device_name: process.env.VUE_APP_DEVICE,
          language: self.$i18n.locale,
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then(function (response) {
          //console.log(response.data);
          //self.cars = response.data.data;
          response.data.data.forEach(car => (car.is_temporary === 0) ? self.savedCars.push({id : car.id, text: car.license_plate, capacity: car.loading_capacity}) : self.tempCars.push({id : car.id, text: car.license_plate, capacity: car.loading_capacity}));
          self.cars = self.savedCars.concat(self.tempCars);
        }).catch(function (error) {
          self.$swal(error.message);
        });
    },
    getAddresses(){
      const self = this;

        axios.get(process.env.VUE_APP_API_URL+'addresses', {
          device_name: process.env.VUE_APP_DEVICE,
          language: self.$i18n.locale,
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then(function (response) {
          //self.cars = response.data.data;

          response.data.data.forEach(
              address => self.allAddresses.push({ id : address.id, text: address.shipping_zip+' '+address.shipping_city+' '+address.shipping_street, shipping_zip: address.shipping_zip, shipping_city: address.shipping_city, shipping_street: address.shipping_street, shipping_country: address.shipping_country})
          );

          response.data.data.forEach(
              address => !address.is_temporary && self.savedAddresses.push({saved : true, id : address.id, text: address.shipping_zip+' '+address.shipping_city+' '+address.shipping_street, shipping_zip: address.shipping_zip, shipping_city: address.shipping_city, shipping_street: address.shipping_street, shipping_country: address.shipping_country})
          );

          response.data.data.forEach(
              address => address.is_temporary && self.addresses.push({saved : false, id : address.id, text: address.shipping_zip+' '+address.shipping_city+' '+address.shipping_street, shipping_zip: address.shipping_zip, shipping_city: address.shipping_city, shipping_street: address.shipping_street, shipping_country: address.shipping_country})
          );
        }).catch(function (error) {
          self.$swal(error.message);
        });
    },
    getCountries(){
      const self = this;

        axios.get(process.env.VUE_APP_API_URL+'constants/countries', {
          device_name: process.env.VUE_APP_DEVICE,
          language: self.$i18n.locale,
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          }
        }).then(function (response) {
          response.data.data.forEach(country => self.countries.push({id : country.id, text: country.text}));
        }).catch(function (error) {
          self.$swal(error.message);
        });
    },
    updateCar(){
      const self = this;

      axios.put(process.env.VUE_APP_API_URL+'cars/'+self.editableCar.id, {
        loading_capacity:self.editableCar.loading_capacity,
        license_plate: self.editableCar.license_plate
      },{
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        if(response.status === 200){
          self.$swal(i18n.t('car-updated-succesfully-label'));
          self.getCars();
        }
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    changeAddress(){
      const self = this;
      self.address.saveAddress = true
      self.allAddresses.forEach(element => element.id === self.address.address_id ? self.modifyAddress(element) : null);
    },
    changeCar(){
      this.cars.forEach(element => element.id == this.vehicles.car_id ? this.modifyCar(element) : null);
    },
    modifyAddress(element){
      this.address.address_id = element.id;
      this.address.shipping_zip = element.shipping_zip;
      this.address.shipping_city = element.shipping_city;
      this.address.shipping_country = element.shipping_country;
      this.address.shipping_street = element.shipping_street;
    },
    modifyCar(element){
      this.vehicles.car_id = element['id'];
      this.vehicles.license_plate = element['text'];
      this.vehicles.loading_capacity = element['capacity'];
    },
    validate(){
      const self = this;

      self.$v.$touch()

      if (!self.$v.$invalid) {
        self.$parent.order.address_id = self.address.address_id;
        self.$parent.order.address.shipping_country = self.address.shipping_country;
        self.$parent.order.address.shipping_city = self.address.shipping_city;
        self.$parent.order.address.shipping_street = self.address.shipping_street;
        self.$parent.order.address.shipping_zip = self.address.shipping_zip;
        self.$parent.order.address.shipping_address_save = self.address.saveAddress;
        self.$parent.order.car_save = self.address.saveCar;

        self.$parent.order.car_id = self.vehicles.car_id;
        self.$parent.order.car.license_plate= self.vehicles.license_plate;
        self.$parent.order.car.loading_capacity= self.vehicles.loading_capacity;

        //self.$parent.order.pickups[0].vehicles[0].push({car_id: self.vehicles.car_id});

        self.$parent.currentStep++;
        !self.$attrs.enablePayment && self.$parent.$refs.Payment.validate();
        window.scrollTo(0,0);
      }
    },
    getZipCity(type){
      const self = this;
      var autoCompleteData;

      if(type === 'zip'){
        autoCompleteData = {
          zip:self.address.shipping_zip
        }
      }
      if(type === 'city'){
        autoCompleteData = {
          city:self.address.shipping_city
        }
      }

      axios.post(process.env.VUE_APP_API_URL+'zip-city/', autoCompleteData,{
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        if(response.status === 200){

          self.zips = null;
          self.cities = null;

          if(type === 'city'){
            self.zips = response.data.data;
          }
          if(type === 'zip'){
            self.cities = response.data.data;
          }
        }
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    copyZipCity(type,zip,city){
      const self = this;
      self.address.shipping_zip = zip;
      self.address.shipping_city = city;

      self.zips = null;
      self.cities = null;
    }
  },
  mounted () {
    $('.select').select2();
    this.getCars();
    this.getAddresses();

    if(this.$parent.order.car) {
      this.vehicles.car_id = this.$parent.order.car_id;
      this.vehicles.license_plate = this.$parent.order.car.license_plate;
      this.vehicles.loading_capacity = this.$parent.order.car.loading_capacity;
    }

    this.getCountries();
  }
}

</script>
