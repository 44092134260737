<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            @click="close">
          <i class="icon-close font-size-11"></i>
        </button>
      </div>

      <section class="modal-body">
        <slot name="body">
          <div class="row">
            <div class="col-12 pb-3">
              <h4>{{ $t('edit-company-label') }}</h4>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.billing_city.$error }">
                <div class="input-box">
                  <label>{{ $t('billing-city-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="billing_city" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.billing_zip.$error }">
                <div class="input-box">
                  <label>{{ $t('billing-zip-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="billing_zip" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.billing_country.$error }">
                <div class="select-box compact">
                  <label>{{$t('billing-country-label')}}</label>
                  <Select2 id="billing_country" v-model="billing_country" :options="countries" />
                </div>
                <div class="error">{{ $t('please-select-label') }}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.billing_street.$error }">
                <div class="input-box">
                  <label>{{ $t('billing-address-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="billing_street" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group" :class="{ 'form-group-error': $v.email.$error }">
                <div class="input-box">
                  <label>{{ $t('email-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="email" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12 col-md-6 border-bottom">
              <div class="form-group" :class="{ 'form-group-error': $v.phone.$error }">
                <div class="input-box">
                  <label>{{ $t('phone-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="phone" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <button role="button" @click="saveCompany()" class="btn btn-primary">{{ $t('save-label') }}</button>
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
const axios = require('axios').default;

export default {
  name: 'Modal',
  data(){
    return {
      clientId:  null,
      billing_zip: this.$parent.user.billing_zip,
      billing_city: this.$parent.user.billing_city,
      billing_country: this.$parent.user.billing_country,
      billing_street: this.$parent.user.billing_street,
      email: this.$parent.user.email,
      phone: this.$parent.user.phone,
      countries: [],
    }
  },
  validations: {
    billing_zip: {
      required
    },
    billing_city: {
      required
    },
    billing_country: {
      required
    },
    billing_street: {
      required
    },
    email: {
      required
    },
    phone: {
      required
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getCountries(){
      const self = this;

      axios.get(process.env.VUE_APP_API_URL+'constants/countries', {
        device_name: process.env.VUE_APP_DEVICE,
        language: self.$i18n.locale,
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        response.data.data.forEach(country => self.countries.push({id : country.id, text: country.text}));
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    saveCompany(){
      const self = this
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      self.$v.$touch()
      if (!self.$v.$invalid) {
        axios.put(process.env.VUE_APP_API_URL+'client/'+self.clientId+'?billing_city='+self.billing_city+'&billing_country='+self.billing_country+'&billing_zip='+self.billing_zip+'&billing_street='+self.billing_street+'&email='+self.email+'&phone='+self.phone, {},config).then(function (response) {
          if(response.status == 200){
            self.$swal(self.$i18n.t('successfully-saved-label'));
            self.close()
            self.$parent.getProfile()
          }
        }).catch(function (error) {
          self.$swal(error.message);
        });
      }
    }
  },
  mounted() {
    this.getCountries();
    this.clientId = this.$parent.user.id
  }
};
</script>
