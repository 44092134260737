<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            @click="close">
          <i class="icon-close font-size-11"></i>
        </button>
      </div>

      <section class="modal-body">
        <slot name="body">
          <div class="row">
            <div class="col-12 pb-3">
              <h4>{{ $t('change-password-label') }}</h4>
            </div>
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group-error': $v.password_old.$error }">
                <div class="input-box">
                  <label>{{ $t('old-password-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="password_old" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group-error': $v.password_new.$error }">
                <div class="input-box">
                  <label>{{ $t('new-password-label') }}</label>
                  <div class="input-group">
                    <input type="password" v-model="password_new" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group-error': $v.password_new_again.$error }">
                <div class="input-box">
                  <label>{{$t('new-password-again-label')}}</label>
                  <input type="password" v-model="password_new_again" class="form-control">
                </div>
                <div class="error" v-if="!$v.password_new_again.required">{{ $t('this-field-is-required-label')}}</div>
                <div class="error" v-if="!$v.password_new_again.sameAs">{{ $t('must-match-password-label')}}</div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <button role="button" @click="savePassword()" class="btn btn-primary">{{ $t('save-label') }}</button>
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import {required, sameAs} from "vuelidate/lib/validators";
const axios = require('axios').default;

export default {
  name: 'Modal',
  data(){
    return {
      clientId:  null,
      password_old: null,
      password_new: null,
      password_new_again: null,
    }
  },
  validations: {
    password_old: {
      required
    },
    password_new: {
      required
    },
    password_new_again: {
      required,
      sameAsPassword: sameAs('password_new')
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    savePassword(){
      const self = this
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      self.$v.$touch()
      if (!self.$v.$invalid) {
        axios.post(process.env.VUE_APP_API_URL+'password?current_password='+self.password_old+'&password='+self.password_new+'&password_confirmation='+self.password_new_again, {},config).then(function (response) {
          if(response.status == 200){
            self.$swal(self.$i18n.t('successfully-saved-label'));
            self.close()
            self.$parent.getProfile()
          }
        }).catch(function (error) {
          self.$swal(error.message);
        });
      }
    }
  },
  mounted() {
    this.clientId = this.$parent.user.id
  }
};
</script>
