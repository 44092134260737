<template>
  <div class="table-orders">
    <table id="todays-order" class="table table-compact table-striped table-responsive-md" cellspacing="0" width="100%">
      <thead>
      <tr>
        <th class="th pl-md-3">{{ $t('num-short-label') }}</th>
        <th class="th">{{ $t('type-label') }}</th>
        <th class="th">{{ $t('time-label') }}</th>
        <th class="th">m<sup>2</sup></th>
        <th class="th">{{ $t('license-plate-label') }}</th>
        <th class="th">{{ $t('payment-label') }}</th>
        <th class="th">{{ $t('delivery-address-label') }}</th>
        <th class="th">{{ $t('comments-label') }}</th>
        <th class="th text-center">{{ $t('status-label') }}</th>
        <th class="th"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in orders" :key="item.id">
        <td class="col-number pl-md-3">
          <a href="#">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block">
                <div class="text-dark">
                  #{{ item.id }}
                </div>
              </div>
            </router-link>
          </a>
        </td>
        <td class="col-type" v-bind:data-th=" $t('type-label') ">
          <div class="text-decoration-none">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block">
                <Type :type="item.grass_type"/>
              </div>
            </router-link>
          </div>
        </td>
        <td data-th="Time" class="col-time">
          <div class="text-decoration-none">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block">
                <p class="text-dark font-weight-bold mb-0 font-size-13 pr-2">{{ createTime(item.pickup_time) }}</p>
                <p class="text-dark font-weight-medium mb-0 font-size-11">{{ createDate(item.pickup_time) }}</p>
              </div>
            </router-link>
          </div>
        </td>
        <td class="rolls-col">
          <div class="text-decoration-none">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block text-dark font-weight-bold">
                {{ item.number_of_rolls }} m<sup>2</sup>
              </div>
            </router-link>
          </div>
        </td>
        <td class="col-delivery" v-bind:data-th=" $t('delivery-label') ">
          <div>
            <span class="text-black">{{ item.car && item.car.license_plate }} <br></span>
            <span class="d-none">{{ $t(item.delivery_type) }} <br></span>
            <span class="d-md-none">{{ item.address.shipping_city }}, {{ item.address.shipping_street }}<br></span>
            <span class="d-md-none">{{ $t(item.payment_type) }}</span>
            <div class="d-block d-md-none" v-if="item.comments.length !== 0">
              <div><i class="icon-notes"></i> {{item.comments.length && item.comments[0].text}}</div>
            </div>
          </div>
        </td>
        <td class="col-payment-status">
          <div>
            <span class="d-none d-md-block">{{ $t(item.payment_type) }}</span>
          </div>
        </td>
        <td class="col-comment">
          <div v-if="item.address">
            {{ item.address.shipping_city }}, {{ item.address.shipping_street }}
          </div>
        </td>
        <td class="col-comments text-center">
          <div class="text-decoration-none d-none d-md-inline-block">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block font-size-10" v-if="item.comments.length !== 0 || item.fertilizer">
                <div class="row">

                  <div class="col-auto px-1" v-show="item.comments[0]"><i class="icon-notes" v-tooltip="item.comments.length && item.comments[0].text"></i></div>
                  <div class="col-auto px-1" v-show="item.fertilizer_bags && item.fertilizer_type !== '0'"><span class="badge badge-grey bg-dark px-1"><i class="icon-leaf" v-tooltip="item.fertilizer_type+': '+item.fertilizer_bags"></i></span></div>

                </div>
              </div>
            </router-link>
          </div>
        </td>
        <td class="col-status text-center">
          <div class="text-decoration-none">
            <router-link :to="'/order/'+item.id">
              <div class="d-inline-block font-size-10">
                <div v-show="item.marked_for_delete"><div class="status status-danger"/></div>
                <div v-show="!item.marked_for_delete" :class="item.client_order_status_icon"/>
              </div>
            </router-link>
          </div>
        </td>
        <td class="col-action text-right">
          <router-link :to="'/order/'+item.id+'/edit'" v-show="item.is_editable && !item.marked_for_delete">
            <div class="btn btn-sm btn-success"><i class="icon-edit"></i></div>
          </router-link>
          <router-link :to="'/order/'+item.id">
            <div class="btn btn-sm btn-white font-size-11 d-none d-md-inline-block"><i class="icon-carousel-right"></i></div>
          </router-link>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import moment from "moment";
import Type from "@/components/Elements/Type";

export default {
  name: 'orders-table',
  components: {Type},
  props:['orders'],
  methods:{
    createDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    createTime(date) {
      return moment(date).format('HH:mm')
    }
  }
}
</script>
