<template>
  <div class="edit-order app py-5" v-if="order">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row border-bottom py-2">
                  <div class="col-auto font-italic text-muted" v-show="!order.is_editable || order.marked_for_delete">
                    {{$t('order-is-not-editable-label')}}
                  </div>
                  <div class="col-auto">
                    <router-link v-show="order.is_editable && !order.marked_for_delete" :to="'/order/'+order.id+'/edit'">
                      <div class="btn btn-pre-square text-decoration-none font-size-12 font-weight-medium p-0">
                        <i class="icon-edit text-green mr-2"></i>
                        {{$t('edit-order-label')}}
                      </div>
                    </router-link>
                  </div>
                  <div class="col-auto">
                      <div v-show="order.is_editable && !order.marked_for_delete" @click="showDeleteOrderModal = true" class="btn btn-pre-square btn-pre-square-red text-decoration-none font-size-12 font-weight-medium p-0">
                        <i class="icon-trash text-danger mr-2"></i>
                        {{$t('delete-order-label')}}
                      </div>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col-12 col-lg-4 pb-3 pr-md-3 border-lg-right pt-lg-3">
                    <div class="row">
                      <div class="col-12">
                        <Type :type="order.grass_type"/>
                        <span class="font-weight-bold font-size-32 d-block mt-3">{{ order.total_area }} m<sup>2</sup></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-8">
                    <div class="row align-items-center">
                      <div class="col-12 pt-lg-3 font-size-14 font-weight-bold">
                        {{ $t('client-portal-pickup-label')}}:
                      </div>
                      <div class="col-6 col-md-6 pt-3">
                        <span class="d-block font-size-14 font-weight-medium">{{ $t('date-label')}}</span>
                        <div class="font-weight-light font-size-20">{{ getDate(order.pickup_time) }}</div>
                      </div>
                      <div class="col-6 col-md-6 pt-3">
                        <span class="d-block font-size-14 font-weight-medium">{{ $t('time-label')}}</span>
                        <div class="font-weight-light font-size-20">{{ getTime(order.pickup_time) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-top py-3">
                  <div class="col-12 col-md-4 border-md-right">

                    <span class="font-weight-medium mb-3 d-block">{{$t('billing-address-label')}}</span>

                    <span class="font-weight-medium mb-3 d-block">{{ order.name }}</span>
                    <span class="text-muted font-size-13 d-block">{{ order.client.billing_street }}</span>
                    <span class="text-muted font-size-13 d-block">{{ $t(order.client.billing_city)}}</span>
                    <span class="text-muted font-size-13 d-block">{{ order.client.billing_zip }}</span>
                    <span class="text-muted font-size-13 d-block">{{ $t(order.client.billing_country)}}</span>
                  </div>
                  <div class="col-12 col-md-4 border-md-right pt-3 pt-md-0">
                      <span class="font-weight-medium mb-3 d-block">{{$t('delivery-address-label')}}</span>
                      <span class="text-muted font-size-13 d-block">{{ order.address.shipping_street }}</span>
                      <span class="text-muted font-size-13 d-block">{{ order.address.shipping_city }}</span>
                      <span class="text-muted font-size-13 d-block">{{ order.address.shipping_zip }}</span>
                      <span class="text-muted font-size-13 d-block">{{ $t(order.address.shipping_country) }}</span>
                  </div>
                  <div class="col-12 col-md-4 pt-3 pt-md-0">
                    <div class="row">
                      <div class="col-12">
                        <span class="font-weight-medium font-size-13 d-inline-block" v-if="order.car">{{ $t('license-plate-label') }}</span>
                        <span class="text-muted font-size-13 ml-1" v-if="order.car">{{ order.car.license_plate }}</span>
                      </div>

                      <div class="col-12">
                        <span class="font-weight-medium font-size-13 d-inline-block">{{ $t('payment-label') }}</span>
                        <span class="text-muted font-size-13 ml-1">{{ $t(order.payment_type) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-12 pt-4" v-if="order.comments">
            <div class="card">
              <div class="card-body">
              <h6 class="font-size-16 font-weight-medium">{{$t('notes-label')}}</h6>

                <table class="table table-compact table-border-bottom mt-3" cellspacing="0" width="100%">
                  <tbody>
                  <tr v-for="comment in order.comments" v-bind:key="comment.id" v-show="comment.type === 'client-label'">
                    <td class="parentElement">
                      <div class="row">
                        <div class="col-11 pl-4">
                          <i class="icon-notes ml-n4 mr-2"></i> &nbsp;{{ comment.text }}
                          <span class="text-muted d-block mb-2 ml-0 pl-0 font-size-10">
                                {{ getDate(comment.created_at) }}
                            </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-compact table-border-bottom" cellspacing="0" width="100%">
                  <tbody>
                  <tr>
                    <td class="font-weight-bold pl-0">{{$t('area-label')}}</td>
                    <td>{{ order.field.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold pl-0">{{$t('delivery-label')}}</td>
                    <td>
                      {{ $t(order.delivery_type) }}
                    </td>
                  </tr>
                  <tr v-if="order.car.loading_capacity">
                    <td class="font-weight-bold pl-0">{{$t('capacity-label')}}</td>
                    <td>
                      {{ $t(order.car.loading_capacity) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold pl-0">{{ $t('order-number-label') }}</td>
                    <td>{{ order.id }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold pl-0">{{ $t('client-id-label') }}</td>
                    <td>{{ order.client_id }}</td>
                  </tr>
                  <tr v-show="order.fertilizer_bags">
                    <td class="font-weight-bold pl-0">{{ $t('fertilizer-label') }}</td>
                    <td>{{ order.fertilizer_bags }} {{ $t('bag-label') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 pt-4">
            <div class="card">
              <div class="card-body">
                <h6 class="font-size-10">{{ $t('technical-info-label')}}</h6>
                <h4 class="font-size-16 mb-3">{{ $t(order.roll_type)}}</h4>
                <table class="table table-compact table-border-bottom" cellspacing="0" width="100%">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold pl-0">{{$t('length-label')}}</td>
                      <td>
                        250 cm
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold pl-0">{{$t('width-label')}}</td>
                      <td>40 cm</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold pl-0">{{$t('rolls-per-pallett-label')}}</td>
                      <td>
                        {{ order.rolls_per_pallet }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold pl-0">{{$t('pallet-size-label')}}</td>
                      <td>120 cm x 100 cm</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold pl-0">{{$t('number-of-pallets-label')}}</td>
                      <td>{{order.number_of_pallets}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

    <DeleteOrder
        v-if="showDeleteOrderModal"
        @close="showDeleteOrderModal = false"
        :orderid="this.id"
    />
  </div>
</template>
<script>
import moment from "moment";
import Type from "@/components/Elements/Type";

const axios = require('axios').default;
import DeleteOrder from '../components/Modals/DeleteOrder.vue';
import {logout} from "@/main";

export default {
  name: 'view-order',
  props:['id'],
  data: function () {
    return {
      order: null,
      showDeleteOrderModal: false,
    }
  },
  components: {
    Type,
    DeleteOrder
  },
  methods:{
    getOrder(){
      const self = this;
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.get(process.env.VUE_APP_API_URL+'orders/'+self.$props.id,
          config
      ).then(function (response) {
        self.order = response.data.data;
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    getDate(date){
      return moment(date).format('YYYY-MM-DD');
    },
    getTime(date){
      return moment(date).format('HH:mm');
    }
  },
  beforeMount () {
    this.getOrder();
  },
  mounted () {
  }
}
</script>
