<template>
  <div class="my-account app py-5" v-if="user">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-12">
                <h5>{{ $t('company-information-label') }}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 border-md-right">
                <p class="row-title">{{user.name}}</p>
                <p class="row-text"><span>{{user.billing_city}}</span></p>
                <p class="row-text"><span>{{user.billing_street}}</span></p>
                <p class="row-text"><span>{{user.billing_zip}} </span></p>
                <p class="row-text"><span>{{$t(user.billing_country)}}</span></p>
              </div>
              <div class="col-12 col-md-6 mt-5 mt-md-0">
                <p class="row-title">{{ user.full_name }}</p>
                <p class="row-text">{{ $t('phone-label') }}: <span>{{ user.phone }}</span></p>
                <p class="row-text">{{ $t('email-label') }}: <span>{{ user.email }}</span></p>
              </div>
            </div>
            <div class="row border-bottom pb-3">
              <div class="col-12">
                <button class="btn btn-link btn-pre-square btn-pre-square-green text-decoration-none"
                        type="button" @click="[selectedUser = user, showEditCompanyModal = true]">
                  <i class="icon-edit text-green mr-2"> </i>
                  {{ $t('edit-company-label') }}
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 pt-3">
                <p class="row-title">{{ $t('addresses-label') }}</p>
                <div v-for="address in user.addresses" v-bind:key="address.id">
                  <div class="row justify-content-between align-items-center" v-show="!address.is_temporary">
                    <div class="col py-2">
                      <span class="d-block font-size-14 text-muted">{{ address.shipping_zip }}</span>
                      <span class="d-block font-size-14 text-muted">{{ address.shipping_city }}</span>
                      <span class="d-block font-size-14 text-muted">{{ address.shipping_street }}</span>
                    </div>
                    <div class="col text-right" v-show="address.is_primary">
                      <div class="font-weight-bold font-size-10">{{ $t('primary-label') }}</div>
                    </div>
                    <div class="col text-right">
                      <button class="btn btn-link btn-pre-square btn-pre-square-green text-decoration-none"
                              type="button" @click="[selectedUser = user, currentAddress = address, showEditAddressModal = true]">
                        <i class="icon-edit text-green mr-2"> </i>
                        {{ $t('edit-label') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button type="button" role="button" class="btn btn-primary" @click="[currentAddress = null, showEditAddressModal = true]"><span>{{$t('add-address-label')}}</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-12">
                <h5>{{ $t('payment-label') }}</h5>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-6">
                <div class="form-group">
                  <div class="select-box compact">
                    <label for="payment-type">{{$t('payment-type-label')}}</label>

                    <Select2 id="payment-type" v-model="user.payment_type" :options="[{id:'0', text: $t('please-select-label')},{id:'cash-label', text: $t('cash-label')},{id:'bank-transfer-label', text: $t('bank-transfer-label')}]" :settings="{minimumResultsForSearch: -1}"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="checkbox-container">
                    <input type="checkbox" style="display:none" id="save-as-default" name="save-as-default" class="inp-cbx" value="1" v-model="user.should_skip_payment_step">
                    <label class="cbx square" for="save-as-default">
                    <span>
                      <svg viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg>
                    </span>
                      <span>{{$t('i-would-like-to-use-default-label')}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-0">
              <div class="col-12">
                <button type="button" role="button" class="btn btn-primary" v-on:click="updateProfile"><span>{{ $t('save-label') }}</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 card-title">
                    <h5>{{ $t('my-cars-label') }}</h5>
                  </div>
                </div>
                <div class="row mb-3 align-items-center border-bottom pb-3" v-for="car in user.cars" :key="car.id" v-show="car.is_temporary === 0">
                  <div class="col-10">
                    <div class="row align-items-center">
                      <div class="col-6 border-right">
                        <p class="text-muted d-block font-size-10 mb-0">{{ $t('license-plate-label') }}</p>
                        <span class="font-weight-medium font-size-13">{{ car.license_plate}}</span>
                      </div>
                      <div class="col-6">
                        <p class="text-muted d-block font-size-10 mb-0">{{ $t('loading-capacity-label') }}</p>
                        <span class="font-weight-medium font-size-13" v-show="car.loading_capacity && car.loading_capacity != 'null'">{{ car.loading_capacity}} kg</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-right">
                    <button class="btn btn-link btn-pre-square btn-pre-square-green text-decoration-none"
                            type="button" @click="[selectedCar = car, showEditCarModal = true]">
                      <i class="icon-edit text-green mr-2"> </i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="button" role="button" class="btn btn-primary" @click="[selectedCar = null, showEditCarModal = true]"><span>{{$t('add-car-label')}}</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 card-title">
                    <h5>{{ $t('password-label') }}</h5>
                  </div>
                  <div class="col-12">
                    <p class="text-muted">************</p>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-link btn-pre-square btn-pre-square-green text-decoration-none"
                            type="button" @click="[showEditPasswordModal = true]">
                      <i class="icon-edit text-green mr-2"> </i> {{ $t('change-password-label') }}
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <EditCar
        v-if="showEditCarModal"
        @close="showEditCarModal = false"
    />

    <EditCompany
        v-if="showEditCompanyModal"
        @close="showEditCompanyModal = false"
    />

    <EditAddress
        v-if="showEditAddressModal"
        @close="showEditAddressModal = false"
    />

    <EditPassword
        v-if="showEditPasswordModal"
        @close="showEditPasswordModal = false"
    />
  </div>
</template>

<script>
import EditCar from '../components/Modals/EditCar.vue';
import EditCompany from '../components/Modals/EditCompany.vue';
import EditAddress from '../components/Modals/EditAddress.vue';
import EditPassword from '../components/Modals/EditPassword.vue';
import {logout} from "@/main";
const axios = require('axios').default;

export default {
  name: 'my-account',
  data(){
    return {
      user: {},
      showEditCarModal: false,
      showEditCompanyModal: false,
      showEditAddressModal: false,
      showEditPasswordModal: false,
      selectedCar: false,
      currentAddress: false,
    }
  },
  components: {
    EditCar,
    EditCompany,
    EditAddress,
    EditPassword
  },
  methods:{
    getProfile(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'client', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.user = response.data.data
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    updateProfile(){
      const self = this
      const params = {
        payment_type : self.user.payment_type,
        should_skip_payment_step: self.user.should_skip_payment_step,
      }
      params.toString();

      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.put(process.env.VUE_APP_API_URL+'client/'+self.user.id,
          {
            payment_type : self.user.payment_type,
            should_skip_payment_step: self.user.should_skip_payment_step
          },
          config
      ).then(function (response) {
        self.user = response.data.data
        localStorage.setItem("user", JSON.stringify(response.data.data));
        self.$parent.user = response.data.data;
        self.$store.commit('changeUser',self.$parent.user);

        self.$swal(self.$i18n.t('successfully-saved-label'));
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    }
  },
  mounted(){
    this.getProfile();
  }
}
</script>
