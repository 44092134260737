<template>
  <div class="tab-content">
    <div class="tab-content-inner tab-content-inner-first p-4">
      <div class="row justify-content-between">
        <div class="col-10 col-md-auto">
          <h3>{{ $t('grass-type-label') }}</h3>
        </div>
        <div class="col-2 col-md-auto">
          <svg v-tooltip="$t('amount-component-tooltip-label')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="24" height="24"
               viewBox="0 0 24 24"
               style=" fill:#000000;"> <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-10 col-md-12">
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="form-group" :class="{ 'form-group-error': $v.order.type.$error }">
                <div class="select-box compact">
                  <label for="type">{{ $t('type-label') }}</label>
                  <Select2 id="type" v-model="order.type" :options="types" :settings="{minimumResultsForSearch: -1}"/>
                </div>
                <div class="error">{{ $t('please-select-label') }}</div>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3">
              <div class="form-group" :class="{ 'form-group-error': $v.order.amount.$error }">
                <div class="input-box">
                  <label for="amount">{{ $t('amount-label') }} ( m<sup>2</sup> )</label>
                  <div class="input-group">
                    <div class="number">
                      <span class="minus" @click="minus">-</span>
                      <input type="number" id="amount" name="amount" value="" pattern="\d+" class="form-control" v-model="order.amount"  @change="this.changeAmount" @keyup="this.changeAmount">
                      <span class="plus" @click="plus">+</span>
                    </div>
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label') }}</div>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 d-none">
              <div class="form-group" :class="{ 'form-group-error': $v.order.number_of_pallets.$error }">
                <div class="input-box">
                  <label for="number_of_pallets">{{ $t('number-of-pallets-label') }}</label>
                  <div class="input-group">
                    <div class="number">
                      <span class="minus" @click="minusPallet">-</span>
                      <input type="number" id="number_of_pallets" name="number_of_pallets" value="" pattern="\d+" class="form-control" v-model="order.number_of_pallets" >
                      <span class="plus" @click="plusPallet">+</span>
                    </div>
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3>{{ $t('delivery-type-label') }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="row">
            <div class="col">
              <div class="form-group" :class="{ 'form-group-error': $v.order.delivery.$error }">
                <div class="checkbox-container">
                  <input type="radio" id="pickuppickup" value="pickup-label" name="delivery_type" class="inp-cbx" v-model="order.delivery">
                  <label class="cbx" for="pickuppickup">
                    <span></span>
                    <span>{{ $t('client-portal-pickup-label') }}</span>
                  </label>
                </div>
                <div class="error">{{ $t('please-select-label') }}</div>
              </div>
            </div>
            <div class="col d-none">
              <div class="form-group">
                <div class="checkbox-container">
                  <input type="radio" id="deliverypickup" value="delivery-label" name="delivery_type" class="inp-cbx" v-model="order.delivery">
                  <label class="cbx" for="deliverypickup">
                    <span></span>
                    <span>{{ $t('delivery-label') }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {required} from "vuelidate/lib/validators";

export default {
  name: 'Amounts',
  data(){
    return {
      types: [],
      order:{
        type: this.$parent.order.grass_type,
        amount: this.$parent.order.total_area == 0 ? 1 : this.$parent.order.total_area,
        number_of_pallets: this.$parent.order.number_of_pallets || 1,
        //delivery: this.$parent.order.pickups[0].delivery_type ? this.$parent.order.pickups[0].delivery_type : 'pickup-label'
        delivery: 'pickup-label' // only pickup available now
      }
    }
  },
  components: {
  },
  validations: {
    order:{
      type: {
        required
      },
      amount: {
        required
      },
      number_of_pallets: {
        required
      },
      delivery: {
        required
      }
    }
  },
  methods:{
    plus(){
      this.order.amount++;
      this.changeAmount();
      return false;
    },
    minus(){
      this.order.amount > 1 && this.order.amount--;
      this.changeAmount();
      return false;
    },
    plusPallet(){
      this.order.number_of_pallets++;
      return false;
    },
    minusPallet(){
      this.order.number_of_pallets > 0 && this.order.number_of_pallets--;
      return false;
    },
    setAmount(){
      return true;
    },
    changeAmount(){
      const pallets = Number(JSON.parse(window.localStorage.user).pallets);
      this.order.number_of_pallets = Math.ceil(this.order.amount / pallets);
    },
    validate(){
      const self = this;

      self.$v.$touch()

      if (!self.$v.$invalid) {
        self.$parent.order.roll_type = 'small-roll-label';
        self.$parent.order.grass_type = self.order.type;
        self.$parent.order.total_area = self.order.amount;
        self.$parent.order.rolls_per_pallet = Number(JSON.parse(window.localStorage.user).pallets);
        self.$parent.order.number_of_rolls = self.order.amount;
        self.$parent.order.number_of_pallets = self.order.number_of_pallets;

        self.$parent.order.roll_type = 'small-roll-label';
        self.$parent.order.grass_type = self.order.type;
        self.$parent.order.delivery_type = self.order.delivery;
        self.$parent.order.total_area = self.order.amount;
        self.$parent.order.rolls_per_pallet = Number(JSON.parse(window.localStorage.user).pallets);
        self.$parent.order.number_of_rolls = self.order.amount;
        self.$parent.order.number_of_pallets = self.order.number_of_pallets;

        self.$parent.$refs['Delivery'].getCalendar();

        self.$parent.currentStep++;
        window.scrollTo(0,0);
      }
    }
  },
  beforeMount () {

    this.garden_stock = JSON.parse(localStorage.info).garden_stock
    this.med_stock = JSON.parse(localStorage.info).med_stock

    this.garden_stock && this.types.push({id: 'normal-label',text:'Garden'})
    this.med_stock && this.types.push({id: 'mediterranean-label',text:'Mediterranean'})

    if(this.$parent.$props.type == 'garden'){
      this.order.type = 'normal-label';
    }
    if(this.$parent.$props.type == 'mediterranean'){
      this.order.type = 'mediterranean-label';
    }
  }
}
</script>
