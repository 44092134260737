<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            @click="close">
          <i class="icon-close font-size-11"></i>
        </button>
      </div>

      <section class="modal-body">
        <slot name="body">
          <div class="row">
            <div class="col-12 pb-3">
              <h4>{{ carId ? $t('edit-car-label') : $t('add-car-label') }}</h4>
            </div>
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group-error': $v.license_plate.$error }">
                <div class="input-box">
                  <label>{{ $t('license-plate-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="license_plate" class="form-control">
                  </div>
                </div>
                <div class="error">{{ $t('this-field-is-required-label')}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div class="input-box">
                  <label>{{ $t('loading-capacity-label') }}</label>
                  <div class="input-group">
                    <input type="text" v-model="loading_capacity" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <button role="button" @click="saveCar()" class="btn btn-primary">{{ $t('save-label') }}</button>
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
const axios = require('axios').default;

export default {
  name: 'Modal',
  data(){
    return {
      carId: null,
      license_plate: null,
      loading_capacity: null
    }
  },
  validations: {
    license_plate: {
      required
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    saveCar(){
        const self = this
        const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

        self.$v.$touch()
        if (!self.$v.$invalid) {
          self.carId ?
          //EDIT CAR
          axios.put(process.env.VUE_APP_API_URL+'cars/'+self.carId+'?license_plate='+self.license_plate+'&loading_capacity='+self.loading_capacity, {},config).then(function (response) {
            if(response.status == 200){
              self.$swal(self.$i18n.t('successfully-saved-label'));
              self.close()
              self.$parent.getProfile()
            }
          }).catch(function (error) {
            self.$swal(error.message);
          }) :
          //CREATE CAR
          axios.post(process.env.VUE_APP_API_URL+'cars?license_plate='+self.license_plate+'&loading_capacity='+self.loading_capacity, {},config).then(function (response) {
            if(response.status == 201){
              self.$swal(self.$i18n.t('successfully-saved-label'));
              self.close()
              self.$parent.getProfile()
            }
          }).catch(function (error) {
            self.$swal(error.message);
          })
        }
    }
  },
  mounted() {
    this.carId = this.$parent.selectedCar.id
    this.license_plate = this.$parent.selectedCar.license_plate
    if(this.$parent.selectedCar.loading_capacity && this.$parent.selectedCar.loading_capacity != 'null'){
      this.loading_capacity = this.$parent.selectedCar.loading_capacity
    }
  }
};
</script>
