<template>
  <div class="faqs app py-5 table-orders">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div id="accordion" class="faq-section">
              <div class="card" v-for="faq in faqs" :key="faq.id">
                <div class="card-header" :id="'faq-'+faq.id">
                  <div class="mb-0">
                    <button class="btn btn-faq collapsed" data-toggle="collapse" :data-target="'#faq-question-'+faq.id" aria-expanded="false" :aria-controls="'faq-question-'+faq.id">
                      {{ faq.question }}
                      <div class="icon-dropdown float-right"/>
                    </button>
                  </div>
                </div>

                <div :id="'faq-question-'+faq.id" class="collapse" :aria-labelledby="'faq-'+faq.id" data-parent="#accordion">
                  <div class="card-body">
                    {{ faq.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import {logout} from "@/main";

const axios = require('axios').default;

export default {
  name: 'faq',
  data(){
    return {
      faqs: {}
    }
  },
  methods:{
    getFaq(){
      const self = this

      const params = {
        locale: i18n.locale
      }

      params.toString();

      axios
          .get(process.env.VUE_APP_API_URL+`faqs/`,{
            params: params,
            headers: {'Authorization': `Bearer ${localStorage.token}`}
          }).then(function (response) {
                self.faqs = response.data.data
              }).catch(function (error) {
                if(error.response.status === 401){
                  logout();
                  return false;
                }
                self.$swal(error.message);
              });
    }
  },
  mounted() {
    this.getFaq();
  }
}
</script>
