<template>
  <div class="tab-content">
    <div class="tab-content-inner tab-content-inner-first p-4">
      <div class="row">
        <div class="col-12">
          <h3>{{ $t('payment-label') }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <div class="row">
            <div class="col-auto">
              <div class="form-group" :class="{ 'form-group-error': $v.payment.type.$error }">
                <div class="checkbox-container">
                  <input type="radio" style="display:none" id="payment_typeCash" name="payment_type" class="inp-cbx" value="cash-label" v-model="payment.type">
                  <label class="cbx" for="payment_typeCash">
                  <span></span>
                  <span>{{ $t('cash-label') }}</span>
                  </label>
                </div>

                <div class="error">{{ $t('please-select-label') }}</div>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <div class="checkbox-container">
                  <input type="radio" style="display:none" id="payment_typeBank" name="payment_type" class="inp-cbx" value="bank-transfer-label" v-model="payment.type">
                  <label class="cbx" for="payment_typeBank">
                  <span></span>
                  <span>{{ $t('bank-transfer-label') }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {required} from "vuelidate/lib/validators";

export default {
  name: 'Payment',
  data(){
    return {
      payment:{
        type: this.$parent.order.payment_type || this.$parent.$parent.$props.user.payment_type || 'cash-label',
      }
    }
  },
  components: {
  },
  validations: {
    payment:{
      type: {
        required
      }
    }
  },
  methods:{
    validate(){
      const self = this;

      self.$v.$touch()

      if (!self.$v.$invalid) {
        self.$parent.order.payment_type = self.payment.type;

        self.$parent.currentStep++;
        window.scrollTo(0,0);
      }
    }
  }
}
</script>

