<template>
  <div class="login w-100 overflow-hidden">
    <div class="row vh-100">
      <div class="col col-sm-12 col-md-6 align-middle bg-white vh-100 login-col">
        <div class="login-inner-col d-table-cell align-middle">
          <LanguageSelector/>
          <div>
            <h1>{{ $t('welcome-label') }}</h1>
            <small class="form-text text-muted">{{ $t('login-to-continue-label') }}</small>

            <form id="login-form" @submit.prevent="loginForm" class="mt-5 pt-5">
              <input type="hidden" name="token" value="">
              <div class="form-group" :class="{ 'form-group-error': $v.user.email.$error }">
                <div class="input-box ">
                  <label for="email">{{ $t('email-label') }}</label>
                  <input type="text" class="form-control" id="email" autocomplete="current-email" v-model="user.email">
                </div>
                <div class="error" v-if="$v.user.email.required">Email is required</div>
                <div class="error" v-if="$v.user.email.email">Valid email is required</div>
              </div>
              <div class="form-group" :class="{ 'form-group-error': $v.user.password.$error }">
                <div class="input-box ">
                  <label for="password">{{ $t('password-label') }}</label>
                  <input type="password" class="form-control" id="password" autocomplete="current-password" v-model="user.password">
                </div>
                <div class="error" v-if="!$v.user.password.required">Password is required</div>
              </div>
              <div class="form-group">
                <button type="submit" role="button" class="btn btn-primary" :disabled="submitStatus"><span>{{ $t('login-label') }}</span></button>
              </div>
              <div class="form-group">
                <router-link to="/login/forgot-password">
                  <div class="text-muted">
                    {{ $t('i-forgot-password-label') }}
                  </div>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col col-sm-12 col-md-6 align-middle p-0 d-none d-md-block">
        <div class="login-bg w-100 h-100 text-center" style="background:url(/img/login-bg.png) no-repeat scroll center/cover transparent">
          <div class="login-bg-inline">
            <img src="/img/logo-white.svg" alt="Turfgrass" class="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/Modules/LanguageSelector"
import { required, email } from 'vuelidate/lib/validators'
import {updateLogin} from "@/main";
const axios = require('axios').default;

export default {
  name: 'Login',
  data(){
    return {
      submitStatus : false,
      user: {
        email: '',
        password: '',
        token: ''
      }
    }
  },
  validations: {
    user:{
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  components: {
    LanguageSelector
  },
  methods:{
    loginForm() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      const self = this;
      self.submitStatus = true

      self.$v.$touch()
      if (self.$v.$invalid) {
        self.submitStatus = false
      } else {
        // do your submit logic here
        setTimeout(() => {
          self.submitStatus = 'OK'

          // LOGIN, GET TOKEN
          axios.post(process.env.VUE_APP_API_URL+'login/client', {
            email: self.user.email,
            password: self.user.password,
            device_name: process.env.VUE_APP_DEVICE,
            language: self.$i18n.locale,
          })
              .then(function (response) {
                //console.log(response);
                  self.user.token = response.data.token

                  // GET USER DATA
                  axios.get(process.env.VUE_APP_API_URL+'client', {
                    data:{
                      id: response.data.client_id
                    },
                    headers: {
                      'Authorization': `Bearer ${self.user.token}`
                    }
                  }).then(function (response) {
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    localStorage.setItem("token", self.user.token);
                    self.$parent.user = response.data.data;
                    self.$store.commit('changeUser',self.$parent.user);

                    updateLogin();

                    self.$router.push({ name: 'Orders' })
                    //localStorage.user = JSON.parse(response.data.data);

                  }).catch(function (error) {
                    self.$swal(error.message);
                    self.submitStatus = false
                    this.$emit('logout');
                  });
              })
              .catch(function (error) {
                self.$swal(error.message);
                self.$swal(self.$i18n.t('wrong-email-or-password-label'));
                self.submitStatus = false
                this.$emit('logout');
              });

        }, 500)
      }
    }
  }
}
</script>
