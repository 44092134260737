<template>
  <div class="loader">
    <div class="video">
      <video autoPlay="true" preload='none' loop="true" playsInline="true" muted="true">
        <source src="/videos/logoAnim.mp4" type='video/mp4;codecs="hvc1"'/>
        <source src="/videos/logoAnim.mp4" type="video/webm" />
      </video>
    </div>
    <span>{{ $t('loading-label') }}...</span>
  </div>
</template>

<script>

export default {
  name: 'Loader',
}
</script>

