<template>
  <div class="app orders py-5">
    <div class="row" v-if="this.orders.history">
      <div class="col">
        <div class="row align-items-center mb-3">
          <div class="col-6 col-md-2">
            <div class="form-group mb-0">
              <div class="select-box compact">
                <label for="year">{{ $t('year-label') }}</label>
                <Select2 id="year" v-model="year" :options="years" :settings="{minimumResultsForSearch: -1}" />
              </div>
            </div>
          </div>
          <div class="col-6 col-md-2">
            <div class="form-group mb-0">
              <div class="select-box compact">
                <label for="month">{{ $t('month-label') }}</label>
                <Select2 id="month" v-model="month" :options="months" :settings="{minimumResultsForSearch: -1}" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <button type="button" role="button" class="btn btn-primary" v-on:click="this.export">{{ $t('export-label') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-show="!this.ordersLoaded">
          <Loader/>
        </div>
        <div class="tab-content" v-show="this.ordersLoaded">
          <div class="tab-content-inner p-4">
            <div class="panel-content text-center mt-4 pb-5" v-if="!this.orders.history">
              <h2>{{ $t('no-orders-label') }}</h2>
            </div>
            <div class="panel-content pb-2" v-if="this.orders.history">
              <div v-for="(order, index) in orders.history" v-bind:key="order.id" v-show="(year === 'all' || createYear(order[0].pickup_time) === year) && (month === 'all' || createMonth(order[0].pickup_time) === month)">
                <div>
                  {{ addYearAndMonth(order[0].pickup_time) }}
                  <h6 class="mt-4">{{index}}</h6>
                  <OrdersTable :orders="order"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrdersTable from '../components/Modules/OrdersTable'
import moment from "moment";
import Loader from '../components/Modules/Loader'
import {logout} from "@/main";
const axios = require('axios').default;


export default {
  name: 'History',
  data(){
    return {
      orders: {},
      ordersLoaded: false,
      year: this.createYear(new Date()),
      month: 'all',
      years:[
        {id: 'all',text: this.$i18n.t('all-label')}
      ],
      months:[
        {id: 'all',text: this.$i18n.t('all-label')}
      ]
    }
  },
  components: {
    OrdersTable,
    Loader
  },
  methods:{

    addYearAndMonth(date){
      const formattedYear = this.createYear(date);
      const formattedMonth = this.createMonth(date);

      if (!this.years.filter(function(e) { return e.id === formattedYear; }).length) {
        this.years.push({id: formattedYear, text: formattedYear})
      }

      if (!this.months.filter(function(e) { return e.id === formattedMonth; }).length) {
        this.months.push({id: formattedMonth, text: this.createMonthString(formattedMonth)})
      }

    },

    createYear(date) {
      return moment(date).format('YYYY')
    },
    createMonth(date) {
      return moment(date).format('MM')
    },
    createMonthString(month){
      switch (month){
        case '01':
          return this.$i18n.t('january-label');

        case '02':
          return this.$i18n.t('february-label');

        case '03':
          return this.$i18n.t('march-label');

        case '04':
          return this.$i18n.t('april-label');

        case '05':
          return this.$i18n.t('may-label');

        case '06':
          return this.$i18n.t('june-label');

        case '07':
          return this.$i18n.t('july-label');

        case '08':
          return this.$i18n.t('august-label');

        case '09':
          return this.$i18n.t('september-label');

        case '10':
          return this.$i18n.t('october-label');

        case '11':
          return this.$i18n.t('november-label');

        case '12':
          return this.$i18n.t('december-label');
      }
    },
    getOrders(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'orders', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.orders = response.data.data
        self.ordersLoaded = true
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    export(){
      const self = this
      self.ordersLoaded = false

      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.post(process.env.VUE_APP_API_URL+'orders/export-history',
          {
            year: self.year,
            month: self.month
          },
          config
      ).then(function (response) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = response.data;
        a.click();
        window.URL.revokeObjectURL(response.data);
        self.ordersLoaded = true
      }).catch(function (error) {
        self.$swal(error.message);
      });
    }
  },
  beforeMount() {
    this.getOrders();
  }
}
</script>
