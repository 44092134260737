<template>
  <div>
    <div v-if="this.$route.name === 'forgotPassword'">
      <ForgotPassword/>
    </div>
    <div v-if="this.$route.name === 'resetPassword' && this.$route.params.token">
      <ResetPassword/>
    </div>
    <div v-else-if="this.$route.name === 'activation' && this.$route.params.token">
      <Activation :token="this.$route.params.token"/>
    </div>
    <div v-else-if="!this.$route.params.token">
      <div v-if="user">
        <Dashboard ref="dashboard" :user="user"/>
      </div>
      <div v-else-if="!user">
        <Login/>
      </div>
    </div>
  </div>
</template>


<script>
import ForgotPassword from "@/components/Layouts/ForgotPassword";
const axios = require('axios').default;
import Dashboard from './components/Layouts/Dashboard'
import Login from './components/Layouts/Login'
import Activation from './components/Layouts/Activation'
import ResetPassword from "@/components/Layouts/ResetPassword";
import moment from "moment"
import {updateLogin} from "@/main";

export default {
  name: 'app',
  data(){
    return {
      user: false
    }
  },
  components: {
    ResetPassword,
    ForgotPassword,
    Dashboard,
    Login,
    Activation
  },
  methods:{
    logout(){
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.$store.commit('changeUser',null);
      localStorage.clear();
      this.user = false;
    },
    getLabels(){
      const self = this;
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.post(process.env.VUE_APP_API_URL+'labels/',
          {locale: 'en'},
          config
      ).then(function (response) {
        const data = response.data.data;
        window.localStorage.setItem('strings', data);
        //console.log(data);
      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    checkLogin(){
      if(!window.localStorage.info || moment().diff(JSON.parse(window.localStorage.info).last_login_at, 'hours') >= 8){
        this.logout();
      }else{
        updateLogin();
      }
    }
  },
  beforeMount() {
    this.checkLogin();
  },
  mounted(){
    if(localStorage.user){
      this.user = JSON.parse(localStorage.user);
      this.$store.commit('changeUser',this.user);
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/app.scss';
</style>
