import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import VueSwal from 'vue-swal'
import router from './router/'
import { BootstrapVue } from 'bootstrap-vue'
import Select2 from 'v-select2-component';
import $ from 'jquery'
import moment from 'moment';
import Vue2TouchEvents from 'vue2-touch-events'
import axios from "axios";
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    changeUser (state, user) {
      state.user = user
    }
  }
})


Vue.use(VueSwal)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(moment)
Vue.use(Vue2TouchEvents)

Vue.component('Select2', Select2);

Vue.config.productionTip = false

Vue.directive('select', {
  twoWay: true,
  bind: function (el) {
    $(el).select2().on("select2:select", (e) => {
      // v-model looks for
      //  - an event named "change"
      //  - a value with property path "$event.target.value"
      el.dispatchEvent(new Event('change', { target: e.target }));
    });
  },
  componentUpdated: function(el) {
    // update the selection if the value is changed externally
    $(el).trigger("change");
  }
});

export function createType(type){
  switch (type){
    case 'normal-label':
      return '<img src={"/images/pannon-garden.svg"}/>'

    case 'mediterrean-label':
      return '<img src={"/images/pannon-garden.svg"}/>'

  }
}


export function getInfo() {
  axios.get(process.env.VUE_APP_API_URL + 'season-last-login', {
    headers: {
      'Authorization': `Bearer ${localStorage.token}`
    }
  }).then(function (response) {
    if (response.status == 200) {
      localStorage.setItem("info",JSON.stringify(response.data));
    }
  }).catch(function (error) {
    console.log(error)
  });
}

export function updateLogin(){
  axios.get(process.env.VUE_APP_API_URL + 'update-last-login', {
    headers: {
      'Authorization': `Bearer ${localStorage.token}`
    }
  }).then(function (response) {
    if (response.status == 200) {
      getInfo();
    }
  }).catch(function (error) {
    console.log(error)
  });
}

export function logout(){
  console.log('logout')
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.href = '/';
}

new Vue({
  i18n,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')

require('select2')


Vue.component("modal", {
  template: "modal-template"
});

$(document).ready(function () {
  $('.select').select2();
})

/* TIME SELECTOR SLIDER */
$(document).on("click",".time-selector .time-selector-slider-arrow",function() {

  var slider = $(this).closest('.time-selector').find('.slider-row');
  var visibleElements = Math.round($(this).closest('.time-selector').outerWidth() / slider.find('li.slider-col').outerWidth());

  if($(this).hasClass('arrow-left')){
    if(slider.find('.slider-col.active').prev().length){
      slider.find('.slider-col.active').prev().addClass('active').siblings().removeClass('active');
      slider.stop().animate({'margin-left':-slider.find('.slider-col.active').index()*slider.find('.slider-col').outerWidth()});
    }
  }
  if($(this).hasClass('arrow-right')){
    if(slider.find('.slider-col').eq(slider.find('.slider-col.active').index()+visibleElements).length){
      slider.find('.slider-col.active').next().addClass('active').siblings().removeClass('active');
      slider.stop().animate({'margin-left':-slider.find('.slider-col.active').index()*slider.find('.slider-col').outerWidth()});
    }
  }
  return false;
});
