<template>
  <div class="login w-100 overflow-hidden">
    <div class="row vh-100">
      <div class="col col-sm-12 col-md-6 align-middle bg-white vh-100 login-col">
        <div class="login-inner-col d-table-cell align-middle">
          <LanguageSelector/>
          <div>
            <h1>{{ $t('activation-label') }}</h1>
            <small class="form-text text-muted">{{ $t('activate-account-to-continue-label') }}</small>

            <form id="login-form" @submit.prevent="ActivateForm" class="mt-5 pt-5">
              <div class="form-group" :class="{ 'form-group-error': $v.user.password.$error }">
                <div class="input-box ">
                  <label for="password">{{ $t('password-label') }}</label>
                  <input type="password" class="form-control" id="password" autocomplete="current-password" v-model="user.password">
                </div>
                <div class="error" v-if="!$v.user.password.required">{{ $t('this-field-is-required-label')}}</div>
              </div>
              <div class="form-group" :class="{ 'form-group-error': $v.user.password_again.$error }">
                <div class="input-box ">
                  <label for="password">{{ $t('password-again-label') }}</label>
                  <input type="password" class="form-control" id="password_again" autocomplete="current-password" v-model="user.password_again">
                </div>
                <div class="error" v-if="!$v.user.password_again.required">{{ $t('this-field-is-required-label')}}</div>
                <div class="error" v-if="!$v.user.password_again.sameAs">{{ $t('must-match-password-label')}}</div>
              </div>
              <div class="form-group">
                <button type="submit" role="button" class="btn btn-primary" :disabled="submitStatus"><span>{{ $t('activate-label') }}</span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col col-sm-12 col-md-6 align-middle p-0 d-none d-md-block">
        <div class="login-bg w-100 h-100 text-center" style="background:url(/img/login-bg.png) no-repeat scroll center/cover transparent">
          <div class="login-bg-inline">
            <img src="/img/logo-white.svg" alt="Turfgrass" class="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/Modules/LanguageSelector"
import { required, sameAs } from 'vuelidate/lib/validators'
const axios = require('axios').default;

export default {
  name: 'Activation',
  data(){
    return {
      submitStatus : false,
      user: {
        password: '',
        password_again: '',
        token: this.$attrs.token
      }
    }
  },
  validations: {
    user:{
      password_again: { required, sameAsPassword: sameAs('password') },
      password: {
        required
      }
    }
  },
  components: {
    LanguageSelector
  },
  methods:{
    ActivateForm() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      const self = this;
      self.submitStatus = true

      self.$v.$touch()
      if (self.$v.$invalid) {
        self.submitStatus = false
      } else {
        // do your submit logic here
        setTimeout(() => {
          self.submitStatus = 'OK'

          // LOGIN, GET TOKEN
          axios.post(process.env.VUE_APP_API_URL+'activate', {
            password: self.user.password,
            token: self.user.token,
            device_name: process.env.VUE_APP_DEVICE,
            language: self.$i18n.locale,
          })
              .then(function (response) {
                if(response.status == 200){
                  self.$swal(self.$i18n.t('activation-successfull-label'));
                  //self.$router.push({ name: 'login' })
                  self.$router.replace('/');
                }
              })
              .catch(function (error) {
                self.$swal(error.message);
                self.submitStatus = false
                this.$emit('logout');
              });

        }, 500)
      }
    }
  },
  mounted(){
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.$parent.user = false;
  }
}
</script>
