<template>
  <div>
    <div class="tab-content">
      <div class="tab-content-inner tab-content-inner-first p-4">
        <div class="row mb-4">
          <div class="col-12">
            <h3 class="review">{{ $t('review-your-data-label') }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg pr-lg-0">
            <div class="row w-100">
              <div class="col-12">
                <Type :type="$parent.order.grass_type"/>
              </div>
              <div class="col-12 pt-3">
                <span class="font-size-32 d-block font-weight-bold">{{ $parent.order.total_area }} m<sup>2</sup></span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg border-lg-right pl-lg-0">
            <div class="row mt-3 mt-lg-0">
              <div class="col-12 font-size-14 font-weight-bold px-0 pb-2">
                {{ $t('client-portal-pickup-label')}}:
              </div>
              <div class="col-6 px-lg-0">
                <span class="font-size-14 font-weight-medium d-block mb-1">{{ $t('date-label') }}</span>
                <span class="font-size-18 text-muted d-block">{{ getDate($t($parent.order.pickup_time)) }}</span>
              </div>
              <div class="col-6 px-lg-0">
                <span class="font-size-14 font-weight-medium d-block mb-1">{{ $t('time-label') }}</span>
                <span class="font-size-18 text-muted d-block">{{ getTime($t($parent.order.pickup_time)) }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg border-lg-right">
            <div class="row mt-4 mt-lg-0">
              <div class="col-12 pb-2">
                <span class="font-weight-medium font-size-14">{{ $t('billing-address-label') }}</span>
              </div>
              <div class="col-12">
              </div>
              <div class="col-12">
                <span class="text-muted font-size-14 d-block">{{ $t($parent.order.billing_country) }}</span>
                <span class="text-muted font-size-14 d-block">{{ $parent.order.billing_city }}</span>
                <span class="text-muted font-size-14 d-block">{{ $parent.order.billing_street }}</span>
                <span class="text-muted font-size-14 d-block">{{ $parent.order.billing_zip }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg">
            <div class="row mt-3 mt-lg-0">
              <div class="col-12">
                <span class="font-weight-medium font-size-14">{{ $t('shipping-type-label') }}: </span>
                <span class="text-muted font-size-14">{{ $t($parent.order.delivery_type === 'pickup-label' ? 'client-portal-pickup-label' : $parent.order.delivery_type) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span class="font-weight-medium font-size-14">{{ $t('payment-label') }}: </span>
                <span class="text-muted font-size-14">{{ $t($parent.order.payment_type) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content-inner p-4 mt-4">
        <div class="row">
          <div class="col-12">
            <h5>{{ $t('shipping-address-label') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-auto border-md-right pr-5">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('country-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $t($parent.order.address.shipping_country) }}</span>
          </div>
          <div class="col-auto border-md-right pr-5">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('post-code-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $parent.order.address.shipping_zip }}</span>
          </div>
          <div class="col-auto border-md-right pr-5">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('city-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $parent.order.address.shipping_city }}</span>
          </div>
          <div class="col-auto pr-5">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('address-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $parent.order.address.shipping_street }}</span>
          </div>
        </div>
      </div>


      <div class="tab-content-inner p-4 mt-4" v-show="$parent.order.car.license_plate">
        <div class="row">
          <div class="col-12">
            <h5>{{ $t('my-car-label') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-auto border-md-right">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('license-plate-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $parent.order.car.license_plate }}</span>
          </div>
          <div class="col-auto border-md-right">
            <span class="text-muted font-size-13 d-block mb-1">{{ $t('loading-capacity-label') }}</span>
            <span class="font-size-16 d-block font-weight-medium mb-3">{{ $parent.order.car.loading_capacity }} kg</span>
          </div>
        </div>

      </div>

      <div class="tab-content-inner p-4 mt-4">
        <div class="row">
          <div class="col-12">
            <h5>{{ $t('fertilizer-label') }}</h5>
          </div>
        </div>
        <div class="row">

          <div class="col-12 col-md-3" v-show="this.fertilizer.need">
            <div class="form-group" :class="{ 'form-group-error': $v.fertilizer.type.$error }">
              <div class="select-box compact">
                <label>{{$t('fertilizer-type-label')}}</label>
                <Select2 id="fertilizer-type" v-model="fertilizer.type" :options="fertilizers" />
              </div>
              <div class="error">{{ $t('please-select-label') }}</div>
            </div>
          </div>
          <div class="col-12 col-md-3" v-show="this.fertilizer.type !== '0'">
            <div class="form-group" :class="{ 'form-group-error': $v.fertilizer.bag.$error }">
              <div class="input-box">
                <label>{{$t('fertilizer-bag-label')}}</label>
                <div class="input-group">
                  <div class="number">
                    <span class="minus" @click="minus">-</span>
                    <input type="number" id="bag" name="bag" value="" pattern="\d+" class="form-control" @keyup="this.changeFertilizerNumber"  v-model="fertilizer.bag">
                    <span class="plus" @click="plus">+</span>
                  </div>
                </div>
              </div>
              <div class="error">{{ $t('this-field-is-required-label') }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="tab-content-inner p-4 mt-4">
        <div class="">
          <div class="row justify-content-between">
            <div class="col-10 col-md-auto">
              <h5>{{ $t('add-comment-label') }}</h5>
            </div>
            <div class="col-2 col-md-auto">
              <svg v-tooltip="$t('comment-component-tooltip-label')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   width="24" height="24"
                   viewBox="0 0 24 24"
                   style=" fill:#000000;"> <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="input-box">
                  <label for="message">{{ $t('message-label') }}</label>
                  <textarea id="message" name="message" class="form-control" v-model="message"></textarea>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-auto"><span class="badge badge-grey" style="cursor:pointer" v-on:click="()=>copyPredefinedComment($t('predefined-comment1-label'))">{{ $t('predefined-comment1-label')}}</span></div>
                <div class="col-auto"><span class="badge badge-grey" style="cursor:pointer" v-on:click="()=>copyPredefinedComment($t('predefined-comment2-label'))">{{ $t('predefined-comment2-label')}}</span></div>
                <div class="col-auto"><span class="badge badge-grey" style="cursor:pointer" v-on:click="()=>copyPredefinedComment($t('predefined-comment3-label'))">{{ $t('predefined-comment3-label')}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";
import moment from "moment";
import Type from "@/components/Elements/Type";
import i18n from '../../i18n'

const axios = require('axios').default;


export default {
  name: 'Review',
  data(){
    return {
      message: this.$parent.order.comments[0] ? this.$parent.order.comments[0].text : '',
      fertilizer: {
        type: this.$parent.order.fertilizer_type || "0",
        bag:this.$parent.order.fertilizer_bags || 1,
        need: true
      },
      fertilizers: []
    }
  },

  validations() {
    return {
      fertilizer:{
        type: {
          required: requiredIf(function(){
            return this.fertilizer.need
          })
        },
        bag: {
          required: requiredIf(function(){
            return this.fertilizer.type !== 0
          }),
        }
      }
    }
  },
  components: {
    Type
  },
  methods:{
    changeFertilizerNumber(e){

      if(e.target.value === "0"){
        this.fertilizer.type = "0"
        this.fertilizer.bag = "1"
      }
    },
    plus(){
      this.fertilizer.bag++;
      return false;
    },
    minus(){
      this.fertilizer.bag > 0 && this.fertilizer.bag--;
      if(this.fertilizer.bag === 0){
        this.fertilizer.type = "0"
        this.fertilizer.bag = "1"
      }
      return false;
    },
    copyPredefinedComment(text){
      this.message = this.message+text+' '
    },
    validate(){
      const self = this;

      self.$v.$touch()

      if (!self.$v.$invalid) {
        self.$parent.order.fertilizer = self.fertilizer.need;
        self.$parent.order.fertilizer_type = self.fertilizer.type;
        self.$parent.order.fertilizer_bags = self.fertilizer.bag;
        self.message && self.$parent.order.comments.push({text:self.message, type: 'office-label'});

        self.$parent.saveOrder();
        window.scrollTo(0,0);
      }
    },
    getFertilizers(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'fertilizers', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {

        self.fertilizers.push({id: 0,text: i18n.t('i-dont-need-fertilizer-label') });
        response.data.data.map(function(fert) {
          if(fert){
            self.fertilizers.push({id: fert.name,text:fert.name});
          }
        });

      }).catch(function (error) {
        self.$swal(error.message);
      });
    },
    getDate(date){
      return moment(date).format('YYYY-MM-DD');
    },
    getTime(date){
      return moment(date).format('HH:mm');
    }

  },
  mounted() {
    this.getFertilizers();
  }
}
</script>
