<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button
            type="button"
            class="btn-close"
            @click="close">
          <i class="icon-close font-size-11"></i>
        </button>
      </div>

      <section class="modal-body">
        <slot name="body">
          <div class="row">
            <div class="col-12">
              <div class="font-size-20 font-weight-bold px-5">{{ $t('order-delete-message-label') }}</div>
            </div>
            <div class="col-12 pt-4">
              <div class="form-group">
                <button role="button" @click="this.delete" class="btn btn-primary">{{ $t('delete-label') }}</button>
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>

const axios = require('axios').default;

export default {
  name: 'Modal',
  props:['orderid'],
  data(){
    return {
      orderId : this.$props.orderid
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    delete() {
      const self = this;
      const config = { headers: {'Authorization': `Bearer ${localStorage.token}`} };

      axios.delete(process.env.VUE_APP_API_URL+'orders/'+self.orderId,config).then(function (response) {
        if(response.status == 200){
          self.$swal(self.$i18n.t('successfully-deleted-label')).then(function() {
            window.location = "/";
          });
          self.close()
        }
      }).catch(function (error) {
        self.$swal(error.message);
      })
    },
  }
};
</script>
