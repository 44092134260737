<template>
  <div id="app">
    <MobileNav></MobileNav>
    <Sidebar :user="$props.user"/>
    <header class="pt-5 mt-5 mt-lg-0 pt-lg-0">
      <nav class="row pl-0 pl-md-3">
        <div class="col-12 col-sm-6">
          <h2>{{ $route.meta.title }}</h2>
        </div>
        <div class="col-12 col-sm-6 float-right pt-2">
          <div class="row justify-content-end">
            <div class="col-auto px-3 ">
              <div class="language-selector">
                <div class="btn-group custom-dropdown">
                  <span>{{this.$i18n.locale}}</span>
                  <button type="button" class="btn btn-sm btn-secondary btn-language dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only"></span>
                  </button>
                  <div class="dropdown-menu p-4">
                    <h4 class="mb-4">Select language</h4>
                    <a class="dropdown-item" href="#" v-on:click="switchLocale('hu')">Magyar</a>
                    <a class="dropdown-item" href="#" v-on:click="switchLocale('en')">English</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <main class="main px-0 px-md-3">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>

import Sidebar from '../Modules/Sidebar'
import MobileNav from '../Modules/MobileNav'

export default {
  name: 'dashboard',
  props:['user'],
  components: {
    MobileNav,
    Sidebar,
  },
  methods:{
    logout(){
      localStorage.clear();
      this.$parent.user = false;
    },
    switchLocale(locale){
      this.$i18n.locale = locale
      localStorage.language = locale
      location.reload();
    }
  },
}
</script>

<link rel="stylesheet" type="text/css" href="https://unpkg.com/select2@4.0.3/dist/css/select2.min.css"/>
