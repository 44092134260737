<template>
  <div class="tab-content">
    <div class="tab-content-inner tab-content-inner-first p-4">
      <div class="row justify-content-center my-5">
        <div class="col-auto">
          <img src="../../assets/checkmark.svg" alt="">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <h2 class="text-center text-green pb-3" v-show="this.$route.name === 'create'">{{ $t('thank-you-for-your-order-label') }}</h2>
          <h2 class="text-center text-green pb-3" v-show="this.$route.name === 'edit'">{{ $t('order-edited-successfully-label') }}</h2>
          <div class="font-weight-medium font-size-20 pt-3 pb-5 text-center">{{ $t('thank-you-for-your-order-text-label') }}</div>
        </div>
      </div>
      <div class="row justify-content-center pb-5">
        <div class="col-auto">
          <a href="/" role="button" class="btn btn-primary">{{ $t('dashboard-label') }}</a>
        </div>
        <div class="col-auto">
          <a :href="this.$parent.$props.id ? '/#/orders/create/garden' : 'javascript:location.reload()'" role="button" class="btn btn-primary">{{ $t('create-new-order-label') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThankYouPage',
}
</script>

